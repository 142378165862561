import { Coordinate } from "./Coordinate"

export class Well {
    id: number
    padId: number
    name: string
    coordinate: Coordinate

    constructor(id, padId, name, coor) {
        this.id = id;
        this.padId = padId
        this.name = name
        this.coordinate = coor
    }

}