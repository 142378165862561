import axios from 'axios';
import { ApiResponse } from '@/types/ApiResponse';
import { ParameterSettingStepData } from '@/types/ParameterSettingStepData';

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL ?? 'http://localhost:8000';

export const queryParameterSetting = async (params: ParameterSettingStepData[]): Promise<ParameterSettingStepData[]> => {
  try {
    console.log("process.env.REACT_APP_API_BASE_URL", process.env.REACT_APP_API_BASE_URL)
    const response = await axios.post<ApiResponse<ParameterSettingStepData[]>>(`${API_BASE_URL}/api/v1/workflow/update/`, params);
    return response.data.data;
  } catch (error) {
    console.error("Error creating item:", error);
    throw error;
  }
};