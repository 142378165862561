import React from 'react';
import { Select, MenuItem, Checkbox, ListItemText, FormControl, InputLabel } from '@mui/material';

interface CustomSelectProps {
  label: string;
  value: string[]; // Array for multiple selections
  onChange: (event: any) => void;
  options: string[];
  error: boolean
}

export default function SidebarMultiSelect({ label, value, onChange, options, error }: CustomSelectProps) {
  return (
    <FormControl variant="outlined" error={error} fullWidth>
      {/* Add a label above the dropdown */}
      <InputLabel sx={{ color: '#fff' }}>{label}</InputLabel>

      {/* The Select component with multi-selection */}
      <Select
        multiple
        value={value}
        onChange={onChange}
        renderValue={(selected) => selected.join(', ')} // Display selected values as comma-separated string
        sx={{
          backgroundColor: '#1a76c7',      // Change background to white for the selection box
          color: '#fff',                
          marginTop: 0,
          marginBottom: 0,
          borderRadius: '4px',          // Rounded corners for the selection field
          '&:hover': {
            backgroundColor: '#1186ed', // Light blue background on hover
          },
          '.MuiSelect-icon': {
            color: '#2b2b2b',              // Color of the dropdown arrow icon (black)
          },
          '& .MuiSelect-select': {
            padding: '14px 0px 14px 12px', // Adjust padding to control height
            minHeight: 'unset', // Ensures padding change affects height
        },
        }}
        MenuProps={{
          PaperProps: {
            style: {
              maxHeight: 300,           // Maximum height for dropdown list
              backgroundColor: '#fff',  // Change the dropdown list background 
              color: '#000',            // Change the text color of dropdown 
            },
          },
        }}
        inputProps={{
          style: { color: '#fff' },     // Input field text color (black)
        }}
      >
        {options.map((option, index) => (
          <MenuItem key={index} value={option} sx={{ backgroundColor: '#fff', color: '#000', '&:hover': { backgroundColor: '#f1f1f1' } }}>
            <Checkbox 
              checked={value.indexOf(option) > -1}
              sx={{
                color: '#ababab',          // Change checkbox color to black
                '&.Mui-checked': {
                  color: '#1a76c7',     // Change color of checked checkbox to blue
                },
              }}
            />
            <ListItemText primary={option} sx={{ color: '#000' }} />
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
}
