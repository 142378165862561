// src/services/getPredictionResults.ts
import axios from 'axios';
import { PredictionResult } from '@/types/PredictionResult';
import { ParameterSettingStepData } from '@/types/ParameterSettingStepData';
import { ProductionHistory } from '@/types/ProductionHistory';

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL ?? 'http://localhost:8000';

// Function to fetch prediction results from the backend
export const getPredictionResults = async (
  params: ParameterSettingStepData[],
  histProd: ProductionHistory
): Promise<PredictionResult> => {
  try {
    // Prepare the request body
    const requestBody = {
      workflowSteps: params, 
      prodHist: histProd,    
    };
    console.log('hist data backend received:', histProd);

    // POST request to the /predict endpoint
    const response = await axios.post(
      `${API_BASE_URL}/api/v1/workflow/predict/`,
      requestBody
    );

    // Extract the data property from the response
    return response.data.data; // Now you receive the prediction data directly
  } catch (error) {
    console.error('Error fetching prediction results:', error);
    throw error;
  }
};
