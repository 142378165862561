import { create } from 'zustand';
import { PredictionResult } from '../types/PredictionResult';

interface ProdForecastState {
  predictionResults: Map<number, Map<number, PredictionResult>>;
  setPredictionResult: (padId: number, wellId: number, result: PredictionResult) => void;
  removeWellPrediction: (padId: number, wellId: number) => void;
  removePadPrediction: (padId: number) => void;
  // Other prediction-related state and actions can be added here
}

const usePredictionStore = create<ProdForecastState>((set, get) => ({
  predictionResults: new Map(),

  setPredictionResult: (padId: number, wellId: number, result: PredictionResult) => {
    const newMap = new Map(get().predictionResults);
    if (!newMap.has(padId)) {
      newMap.set(padId, new Map());
    }
    const padMap = newMap.get(padId);
    padMap.set(wellId, result);
    set({ predictionResults: newMap });
  },

  removeWellPrediction: (padId: number, wellId: number) => {
    const newMap = new Map(get().predictionResults);
    const padMap = newMap.get(padId);
    if (padMap) {
      padMap.delete(wellId);
      if (padMap.size === 0) {
        newMap.delete(padId);
      }
      set({ predictionResults: newMap });
    }
  },

  removePadPrediction: (padId: number) => {
    const newMap = new Map(get().predictionResults);
    newMap.delete(padId);
    set({ predictionResults: newMap });
  },

  // You can add more actions or state related to predictions if needed
}));

export default usePredictionStore;
