import { extractWellParams } from './MeasurementParamsHelper';

export const performConditionalCalculations = (
  configStep,
  selectedPadId,
  selectedWellId,
  allParams,
  setParam
) => {
  if (configStep[selectedPadId]?.[selectedWellId] >= 3) {
    const wellParams = extractWellParams(allParams, selectedPadId, selectedWellId);
    
    if (!wellParams) return;

    const { subsea_top, subsea_bottom, elevation, frac_stages, stage_spacing } = wellParams;

    // Perform calculations
    const tvd = Math.abs(subsea_top + subsea_bottom) / 2 + elevation;
    const lat_length = frac_stages * stage_spacing;
    const md = lat_length + tvd + 439;

    // Set calculated parameters
    setParam(selectedPadId, selectedWellId, "LateralLength_FT", lat_length.toString());
    setParam(selectedPadId, selectedWellId, "MD_FT", md.toString());
    setParam(selectedPadId, selectedWellId, "TVD_FT", tvd.toString());
  }
};
