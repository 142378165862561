// About.js
import React from "react";
import { Typography, Container, Grid } from "@mui/material";
import DeveloperCard from "../components/DeveloperCard";
import "../styles/about.css"
const developers = [
  {
    name: "Ziming Xu",
    photo: "/assets/user.png",
    affiliation: "University of Alberta",
    homepage: "https://google.com",
    description: "Ph.D. Candidate in Petroleum Engineering",
  },
  {
    name: "Hongxuan Liu",
    photo: "/assets/user.png",
    affiliation: "University of Alberta",
    homepage: "https://google.com",
    description: "M.Sc. Student in Computer Engineering",
  },
  {
    name: "Juliana Leung",
    photo: "/assets/user.png",
    affiliation: "University of Alberta",
    homepage: "https://sites.ualberta.ca/~juliana2/",
    description: "Professor",
  },
  // 可以继续添加开发者信息
];

const About = () => {
  return (
    <Container className="about-container">
      <Typography variant="h4" align="center" gutterBottom>
        About Our Team
      </Typography>
      <Typography variant="body1" align="center" paragraph>
        We are a dedicated team of professionals with expertise in various fields of technology, 
        including artificial intelligence, software development, and system design. Our goal is 
        to innovate and create impactful solutions that improve everyday life.
      </Typography>

      <Grid container spacing={2} justifyContent="center">
        {developers.map((developer, index) => (
          <Grid item key={index}>
            <DeveloperCard developer={developer} />
          </Grid>
        ))}
      </Grid>
    </Container>
  );
};

export default About;
