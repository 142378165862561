import { create } from 'zustand';
import { useRef } from 'react'

interface ConfigErrorState {
    configError: Record<number, Record<number, Record<string, string>>>
    setConfigError: (padId: number, wellId: number, record: Record<string, string>) => void
    cleanConfigError: (padId: number, wellId: number, keys: string[]) => void
    cleanAllConfigError: (padId: number, wellId: number) => void
}

const useConfigErrorStore = create<ConfigErrorState>((set, get) => ({

    configError: {1: {1: {}}},

    setConfigError: (padId: number, wellId: number, record: Record<string, string>) => {
        const newMap = {...get().configError};
        if (!newMap[padId]) {
            newMap[padId] = {};
        }
        newMap[padId][wellId] = record
        set((state) => ({configError: newMap}))
    },

    cleanConfigError: (padId: number, wellId: number, keys: string[]) => {
        const newMap = { ...get().configError };
        keys.forEach((key) => {
            if (newMap[padId] && newMap[padId][wellId]) delete newMap[padId][wellId][key];
        });
        set((state) => ({configError: newMap}))
    },

    cleanAllConfigError: (padId: number, wellId: number) => {
        const newMap = {};
        set((state) => ({configError: newMap}))
    }
}));
  
  export default useConfigErrorStore;