export const extractWellParams = (allParams, selectedPadId, selectedWellId) => {
  const step2_data = allParams.get(selectedPadId)?.get(selectedWellId);
  
  if (!step2_data) {
    console.error("step2_data is not available");
    return null;
  }

  // Extract parameters, defaulting to 0 if undefined
  return {
    subsea_top: parseFloat(step2_data["SubseaTopDepth_FT"]) || 0,
    subsea_bottom: parseFloat(step2_data["SubseaBaseDepth_FT"]) || 0,
    elevation: parseFloat(step2_data["ElevationGL_FT"]) || 0,
    frac_stages: parseFloat(step2_data["FracStages"]) || 0,
    stage_spacing: parseFloat(step2_data["AverageStageSpacing_FT"]) || 0,
  };
};