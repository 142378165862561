// DeveloperCard.js
import React from "react";
import { Card, CardContent, CardMedia, Typography, Link } from "@mui/material";

const DeveloperCard = ({ developer }) => (
  <Card style={{ maxWidth: 300, margin: 16, minHeight: 350, minWidth: 300 }}> {/* 设置 minHeight */}
    <CardMedia
      component="img"
      height="140"
      image={developer.photo}
      alt={`${developer.name}'s photo`}
    />
    <CardContent>
      <Typography variant="h6">{developer.name}</Typography>
      <Typography variant="subtitle1" color="textSecondary">
        {developer.affiliation}
      </Typography>
      <Link href={developer.homepage} target="_blank" rel="noopener" underline="hover">
        Personal Homepage
      </Link>
      <Typography variant="body2" color="textSecondary" style={{ marginTop: 8 }}>
        {developer.description}
      </Typography>
    </CardContent>
  </Card>
);

export default DeveloperCard;
