import React from 'react';
import { SelectChangeEvent, Select, MenuItem, Checkbox, ListItemText, FormControl, InputLabel } from '@mui/material';

interface CustomSelectProps {
  label: string;
  value: string;
  onChange: (event: SelectChangeEvent<string>, child: React.ReactNode) => void; 
  options: string[];
  error: boolean;
}

export default function SidebarSelect({ label, value, onChange, options, error }: CustomSelectProps) {
  return (
    <FormControl variant="outlined" error={error} fullWidth>
      <InputLabel sx={{ color: '#fff' ,
        padding: '0px 0px 0px 0px'
      }}>{label}</InputLabel>

      <Select
        label={label}
        fullWidth
        value={value}
        onChange={onChange}
        sx={{
          backgroundColor: '#1a76c7',
          color: '#fff',
          marginTop: 0,
          marginBottom: 0,
          '& .MuiSelect-select': {
            padding: '14px 0px 14px 12px', // Adjust padding to control height
            minHeight: 'unset', // Ensures padding change affects height
        },
        }}
        inputProps={{
          style: { color: '#fff' },
        }}
      >
        {options.map((option, index) => (
          <MenuItem key={index} value={option}>
            {option}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
}
