import { Box, Divider } from '@mui/material';
import PadSidebar from '../components/PadSideBar';
import LineChart from '../components/LineChart';
import React, { Suspense, useState, useEffect } from 'react';
import useWellConfigStore from '../store/useWellConfigStore';
import useWellStore from '../store/useWellStore';
import usePadStore from '../store/usePadStore';
import NavigationBar from '../components/NavigationBar';

const MapComponent = React.lazy(() => import('../components/MapComponent'));

function PredictionPortal() {

  const selectedWellId = useWellStore((state) => state.selectedWellId)

  const selectedPadId = usePadStore((state) => state.selectedPadId)

  const configStep = useWellConfigStore((state) => state.configStep)

  const mapFlex = selectedWellId === null || selectedPadId === null ? 10 : (configStep[selectedPadId][selectedWellId] >= 1 ? 5 : 10);

  return (
      <Box sx={{ display: 'flex', height: '100vh'}}>
          <NavigationBar/>
          {/* left part */}
          <PadSidebar/>
          <Divider orientation="vertical" flexItem sx={{ borderColor: '#4a4a4a', borderWidth: '0px' }} />

          {/* right part */}
          <Box sx={{flexGrow: 1, display: 'flex',  flexDirection: 'column', height: '100vh', overflow: 'hidden'}}>
              <Box sx={{ flex: mapFlex, width: '100%', overflow: 'hidden', flexShrink: 0, transition: 'height 0.3s ease'}}>
              <Suspense fallback={<div>Loading map...</div>}>
                <MapComponent />
              </Suspense>
              </Box>
              <Divider sx={{ borderColor: '#ccc', borderWidth: '0px' }} />

              {/* Poduction curve */}
              {selectedWellId !== null && selectedPadId !== null && configStep[selectedPadId][selectedWellId] >= 5 && (
                  <Box sx={{ flex: 5, backgroundColor: 'black', width: '100%', flexShrink: 0, overflow: 'hidden', transition: 'height 0.3s ease',}}>
                      <LineChart padId={selectedPadId} wellId={selectedWellId} />
                  </Box>
              )}
      </Box>
      
      </Box>
  );
}

export default PredictionPortal;