import axios from 'axios';
import { ApiResponse } from '@/types/ApiResponse';
import { Play, SubPlay } from '@/types/Play';
import playData from '../data/WestCanada_Play.json';
import subPlayMontney from '../data/Montney_subplay.json';

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL ?? 'http://localhost:8000';

export const getPlayList = async (): Promise<Play[]> => { 
  try {
    const allowedPlays = ['MONTNEY', 'DUVERNAY']; // Define allowed play names

    const playList: Play[] = Object.keys(playData)
      .filter((playName) => allowedPlays.includes(playName)) // Filter for allowed plays
      .map((playName, index) => ({
        id: index + 1, // Assign an ID based on the index
        name: playName as 'MONTNEY' | 'DUVERNAY', // Cast to allowed play names
        geometry: playData[playName].map(([lat, lon]: [number, number]) => [lat, lon]), // Directly map geometry assuming the correct format
        subPlays: [], // Initialize as empty array or populate if data is available
      }));

    return playList;
  } catch (error) {
    console.error('Error creating play list:', error);
    throw error;
  }
};

export const getSubPlayList = async (playName: string): Promise<SubPlay[]> => {
  try {
    // Currently, we only have subplays for "MONTNEY"
    if (playName !== "MONTNEY") {
      return [];
    }

    const subPlayList: SubPlay[] = Object.keys(subPlayMontney).map((subPlayName, index) => {
      const geometry = subPlayMontney[subPlayName]; // The geometry array
      return {
        id: index + 1, // Assign an ID based on the index
        playName: "MONTNEY",
        name: subPlayName,
        geometry: geometry,
        presentWellList: [], // Initialize as empty array or populate if data is available
      };
    });

    return subPlayList;
  } catch (error) {
    console.error("Error creating item:", error);
    throw error;
  }
};