// Tutorial.js
import React, { useState, useEffect } from 'react';
import ReactMarkdown from 'react-markdown';
import remarkToc from 'remark-toc';
import remarkGfm from 'remark-gfm';
import rehypeSlug from 'rehype-slug';
import rehypeAutolinkHeadings from 'rehype-autolink-headings';
import '../styles/tutorial.css';

const Tutorial = () => {
    const [markdownContent, setMarkdownContent] = useState('');
    const [headings, setHeadings] = useState([]);
  
    useEffect(() => {
      // 读取 Markdown 文件
      fetch('/docs/tutorial.md')
        .then((response) => response.text())
        .then((text) => {
          setMarkdownContent(text);
          extractHeadings(text);
        });
    }, []);
  
    // 提取标题
    const extractHeadings = (text) => {
      const lines = text.split('\n');
      const headingsList = [];
  
      lines.forEach((line) => {
        const match = line.match(/^(#{1,3})\s+(.*)/);
        if (match) {
          const level = match[1].length;
          const title = match[2];
          const id = title.toLowerCase().replace(/\s+/g, '-'); // 生成 id
          headingsList.push({ level, title, id });
        }
      });
  
      setHeadings(headingsList);
    };
  
    return (
      <div className="tutorial-page">
        <div className="tutorial-content">
          <ReactMarkdown
            children={markdownContent}
            remarkPlugins={[remarkGfm]}
            rehypePlugins={[rehypeSlug, rehypeAutolinkHeadings]}
          />
        </div>
        <div className="tutorial-toc">
          <h3>Table of contents</h3>
          <ul>
            {headings.map((heading, index) => (
              <li key={index} className={`toc-level-${heading.level}`}>
                <a href={`#${heading.id}`}>{heading.title}</a>
              </li>
            ))}
          </ul>
        </div>
      </div>
    );
  };
  
  export default Tutorial;
