import { createContext, useState, useContext, ReactNode } from 'react';

type GlobalSettingContextType = {
};

const GlobalSettingContext = createContext<GlobalSettingContextType | undefined>(undefined);

export const GlobalSettingContextProvider = ({ children }: { children: ReactNode }) => {
    return (
        <GlobalSettingContext.Provider value={{}}>
        {children}
        </GlobalSettingContext.Provider>
    );
};

export const useGlobalSettingContext = (): GlobalSettingContextType => {
    const context = useContext(GlobalSettingContext);
    if (!context) {
        throw new Error('useGlobalSettingContext must be used within a GlobalSettingContextProvider');
    }
    return context;
};