import React, { useState } from 'react';
import {
  Grid,
  Button,
  TextField,
  Typography,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  IconButton,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

interface CustomChartProps {
  timestep?: number;
  qg: string;
  qw: string;
  qo: string;
}

interface SidebarChartProps {
  open: boolean;
  onClose: () => void;
  onConfirm: (data: CustomChartProps[]) => void;
  label: string;
  value: string;
}

export default function FloatingTable({
  open,
  onClose,
  onConfirm,
  label,
}: SidebarChartProps) {
  const [rows, setRows] = useState<CustomChartProps[]>([
    { timestep: 1, qg: '', qw: '', qo: '' },
    { timestep: 2, qg: '', qw: '', qo: '' },
    { timestep: 3, qg: '', qw: '', qo: '' },
    { timestep: 4, qg: '', qw: '', qo: '' },
    { timestep: 5, qg: '', qw: '', qo: '' },
  ]);

  const handleAddRow = () => {
    setRows([...rows, { timestep: rows.length + 1, qg: '', qw: '', qo: '' }]);
  };

  const handleDeleteRow = () =>{
    if (rows.length>1){
      setRows(rows.slice(0,-1))
    }
  }

  const handleInputChange = (
    index: number,
    field: keyof CustomChartProps,
    newValue: string
  ) => {
    const updatedRows = [...rows];
    updatedRows[index] = { ...updatedRows[index], [field]: newValue };
    setRows(updatedRows);
  };

  const handleConfirm = () => {
    onConfirm(rows);
  };

  return (
    <Dialog open={open} onClose={onClose} maxWidth="sm" fullWidth>
      <DialogTitle
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          backgroundColor: '#333',
          borderRadius: '0px',
          color: '#fff',
        }}
      >
        <Typography variant="h6">{label} - Table Editor</Typography>
        <IconButton onClick={onClose} sx={{ color: '#fff' }}>
          <CloseIcon />
        </IconButton>
      </DialogTitle>

      <DialogContent sx={{ backgroundColor: '#222', color: '#fff' }} className="custom-scrollbar">
        <Grid container spacing={0} sx={{ border: '1px solid #ccc' }}>
          {/* Table Headers */}
          {['Timesteps', 'qg (MCF/day)', 'qw (bbl/day)', 'qo (bbl/day)'].map(
            (header, idx) => (
              <Grid
                item
                xs={3}
                key={idx}
                sx={{
                  borderBottom: '1px solid #ccc',
                  borderRight: idx < 3 ? '1px solid #ccc' : 'none',
                  padding: 1,
                  textAlign: 'center',
                }}
              >
                <Typography variant="body2" sx={{ color: '#fff' }}>
                  {header}
                </Typography>
              </Grid>
            )
          )}

          {/* Table Rows */}
          {rows.map((row, index) => (
            <Grid container item xs={12} key={index}>
              <Grid
                item
                xs={3}
                sx={{
                  borderBottom: '1px solid #ccc',
                  borderRight: '1px solid #ccc',
                  padding: 1,
                  textAlign: 'center',
                }}
              >
                <Typography variant="body2" sx={{ color: '#fff' }}>
                  {row.timestep}
                </Typography>
              </Grid>

              {['qg', 'qw', 'qo'].map((field, idx) => (
                <Grid
                  item
                  xs={3}
                  key={idx}
                  sx={{
                    borderBottom: '1px solid #ccc',
                    borderRight: idx < 2 ? '1px solid #ccc' : 'none',
                    padding: 1,
                  }}
                >
                  <TextField
                    type="number"
                    value={row[field as keyof CustomChartProps]}
                    onChange={(e) =>
                      handleInputChange(
                        index,
                        field as keyof CustomChartProps,
                        e.target.value
                      )
                    }
                    fullWidth
                    variant="standard"
                    InputProps={{
                      style: { color: '#fff', textAlign: 'center' },
                    }}
                    inputProps={{
                      autoComplete: 'off', // Add this to disable autofill
                    }}
                  />
                </Grid>
              ))}
            </Grid>
          ))}

          {/* Add Row and Delete Row Buttons */}
          <Grid item xs={12} sx={{ padding: 1, textAlign: 'right', display: 'flex', justifyContent: 'flex-end' }}>

            <Button onClick={handleAddRow} variant="contained" color="primary"     
                sx={{
                  backgroundColor: 'rgba(211, 47, 47, 0)', // Red with 50% transparency
                  '&:hover': {
                    backgroundColor: '#1a76c7', // Darker red with 80% transparency
                  },
                  marginRight: 2,
              }}>
              + Add Row
            </Button>
            <Button
              onClick={handleDeleteRow}
              variant="contained"
              color="primary"
              sx={{
                backgroundColor: 'rgba(211, 47, 47, 0)', // Red with 50% transparency
                '&:hover': {
                  backgroundColor: '#d61c1c', // Darker red with 80% transparency
                },
              }}
            >
              - Delete Row
            </Button>
          </Grid>
        </Grid>
      </DialogContent>

      <DialogActions sx={{ backgroundColor: '#333' }}>
        <Button onClick={handleConfirm} variant="contained" color="primary">
          Confirm
        </Button>
      </DialogActions>
    </Dialog>
  );
}
